import { default as galleryylaXRFNemvMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/gallery.vue?macro=true";
import { default as garnmentsBGuNyz6fkgMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/garnments.vue?macro=true";
import { default as inboxvwYtJ1eh1bMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/inbox.vue?macro=true";
import { default as indexWJU4JTM0lxMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/index.vue?macro=true";
import { default as _91id_9387iPG2kt77Meta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/models/[id].vue?macro=true";
import { default as indexUDG5Kz7AxsMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/models/index.vue?macro=true";
import { default as index2vpUe2dFKqxMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/models/index2.vue?macro=true";
import { default as indexecnE64ifSHMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/settings/index.vue?macro=true";
import { default as membersljl1a6VvUbMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/settings/members.vue?macro=true";
import { default as notifications0AD3bax7XUMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/settings/notifications.vue?macro=true";
import { default as settingsvYpvXqiKCPMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/settings.vue?macro=true";
import { default as users60L4WGRXnMMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/users.vue?macro=true";
export default [
  {
    name: "gallery",
    path: "/gallery",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "garnments",
    path: "/garnments",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/garnments.vue").then(m => m.default || m)
  },
  {
    name: "inbox",
    path: "/inbox",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/inbox.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "models-id",
    path: "/models/:id()",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/models/[id].vue").then(m => m.default || m)
  },
  {
    name: "models",
    path: "/models",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/models/index.vue").then(m => m.default || m)
  },
  {
    name: "models-index2",
    path: "/models/index2",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/models/index2.vue").then(m => m.default || m)
  },
  {
    name: settingsvYpvXqiKCPMeta?.name,
    path: "/settings",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings",
    path: "",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-members",
    path: "members",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/settings/members.vue").then(m => m.default || m)
  },
  {
    name: "settings-notifications",
    path: "notifications",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/settings/notifications.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/users.vue").then(m => m.default || m)
  }
]