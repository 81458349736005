<script setup lang="ts">
const colorMode = useColorMode();
const { getImages } = useFile();

const color = computed(() =>
  colorMode.value === "dark" ? "#111827" : "white"
);

colorMode.preference = "dark";

useHead({
  meta: [
    { charset: "utf-8" },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
    { key: "theme-color", name: "theme-color", content: color },
  ],
  link: [{ rel: "icon", href: "/favicon.ico" }],
  htmlAttrs: {
    lang: "en",
  },
});

const title = "GRAIN AI SAAS";
const description = "GRAIN AI SAAS is a powerful AI tool for your business.";

useSeoMeta({
  title,
  description,
  ogTitle: title,
  ogDescription: description,
  ogImage: "https://dashboard-template.nuxt.dev/social-card.png",
  twitterImage: "https://dashboard-template.nuxt.dev/social-card.png",
  twitterCard: "summary_large_image",
});
await getImages();
</script>

<template>
  <div>
    <NuxtLoadingIndicator />

    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <UNotifications />
    <UModals />
  </div>
</template>

<style>
*:not(input, textarea) {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-active {
  transition: opacity 0.6s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.slideUp-enter-active {
  transition: transform 0.4s ease-in-out;
}

.slideUp-leave-active {
  transition: transform 0.2s ease-in-out;
}

.slideUp-enter-from,
.slideUp-leave-to {
  transform: translate(-50%, 100%);
}

.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
